<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 0C3.36739 0 3.24021 0.052678 3.14645 0.146446C3.05268 0.240214 3 0.367392 3 0.5V1H2C0.900649 1 0 1.90065 0 3V12C0 13.0993 0.900649 14 2 14H12C13.0994 14 14 13.0993 14 12V3C14 1.90065 13.0994 1 12 1H11V0.5C11 0.367392 10.9473 0.240214 10.8536 0.146446C10.7598 0.052678 10.6326 0 10.5 0C10.3674 0 10.2402 0.052678 10.1464 0.146446C10.0527 0.240214 10 0.367392 10 0.5V1H7.5V0.5C7.5 0.367392 7.44732 0.240214 7.35355 0.146446C7.25979 0.052678 7.13261 0 7 0C6.86739 0 6.74021 0.052678 6.64645 0.146446C6.55268 0.240214 6.5 0.367392 6.5 0.5V1H4V0.5C4 0.367392 3.94732 0.240214 3.85355 0.146446C3.75979 0.052678 3.63261 0 3.5 0ZM2 2H3V2.5C3 2.63261 3.05268 2.75979 3.14645 2.85355C3.24021 2.94732 3.36739 3 3.5 3C3.63261 3 3.75979 2.94732 3.85355 2.85355C3.94732 2.75979 4 2.63261 4 2.5V2H6.5V2.5C6.5 2.63261 6.55268 2.75979 6.64645 2.85355C6.74021 2.94732 6.86739 3 7 3C7.13261 3 7.25979 2.94732 7.35355 2.85355C7.44732 2.75979 7.5 2.63261 7.5 2.5V2H10V2.5C10 2.63261 10.0527 2.75979 10.1464 2.85355C10.2402 2.94732 10.3674 3 10.5 3C10.6326 3 10.7598 2.94732 10.8536 2.85355C10.9473 2.75979 11 2.63261 11 2.5V2H12C12.5626 2 13 2.43735 13 3V4H1V3C1 2.43735 1.43735 2 2 2ZM1 5H13V12C13 12.5626 12.5626 13 12 13H2C1.43735 13 1 12.5626 1 12V5Z"
      fill="#68737E"
    />
    <path
      d="M2.75226 6.50195H3.75023C3.8889 6.50195 4.00053 6.61359 4.00053 6.75226V7.75023C4.00053 7.8889 3.8889 8.00053 3.75023 8.00053H2.75226C2.61359 8.00053 2.50195 7.8889 2.50195 7.75023V6.75226C2.50195 6.61359 2.61359 6.50195 2.75226 6.50195Z"
      fill="#68737E"
    />
    <path
      d="M5.2503 6.50195H6.24828C6.38695 6.50195 6.49858 6.61359 6.49858 6.75226V7.75023C6.49858 7.8889 6.38695 8.00053 6.24828 8.00053H5.2503C5.11163 8.00053 5 7.8889 5 7.75023V6.75226C5 6.61359 5.11163 6.50195 5.2503 6.50195Z"
      fill="#68737E"
    />
    <path
      d="M7.75226 6.50195H8.75023C8.8889 6.50195 9.00053 6.61359 9.00053 6.75226V7.75023C9.00053 7.8889 8.8889 8.00053 8.75023 8.00053H7.75226C7.61359 8.00053 7.50195 7.8889 7.50195 7.75023V6.75226C7.50195 6.61359 7.61359 6.50195 7.75226 6.50195Z"
      fill="#68737E"
    />
    <path
      d="M10.2503 6.50195H11.2483C11.3869 6.50195 11.4986 6.61359 11.4986 6.75226V7.75023C11.4986 7.8889 11.3869 8.00053 11.2483 8.00053H10.2503C10.1116 8.00053 10 7.8889 10 7.75023V6.75226C10 6.61359 10.1116 6.50195 10.2503 6.50195Z"
      fill="#68737E"
    />
    <path
      d="M2.75226 9.5H3.75023C3.8889 9.5 4.00053 9.61164 4.00053 9.7503V10.7483C4.00053 10.8869 3.8889 10.9986 3.75023 10.9986H2.75226C2.61359 10.9986 2.50195 10.8869 2.50195 10.7483V9.7503C2.50195 9.61164 2.61359 9.5 2.75226 9.5Z"
      fill="#68737E"
    />
    <path
      d="M5.2503 9.5H6.24828C6.38695 9.5 6.49858 9.61164 6.49858 9.7503V10.7483C6.49858 10.8869 6.38695 10.9986 6.24828 10.9986H5.2503C5.11163 10.9986 5 10.8869 5 10.7483V9.7503C5 9.61164 5.11163 9.5 5.2503 9.5Z"
      fill="#68737E"
    />
    <path
      d="M7.75226 9.5H8.75023C8.8889 9.5 9.00053 9.61164 9.00053 9.7503V10.7483C9.00053 10.8869 8.8889 10.9986 8.75023 10.9986H7.75226C7.61359 10.9986 7.50195 10.8869 7.50195 10.7483V9.7503C7.50195 9.61164 7.61359 9.5 7.75226 9.5Z"
      fill="#68737E"
    />
    <path
      d="M10.2503 9.5H11.2483C11.3869 9.5 11.4986 9.61164 11.4986 9.7503V10.7483C11.4986 10.8869 11.3869 10.9986 11.2483 10.9986H10.2503C10.1116 10.9986 10 10.8869 10 10.7483V9.7503C10 9.61164 10.1116 9.5 10.2503 9.5Z"
      fill="#68737E"
    />
  </svg>
</template>
<script>
export default {
  name: "CalendarIcon",
};
</script>
